<template>
  <modal v-model="modal" max-width="1000px" title="Калькулятор">
    <template v-slot:activator>
      <slot/>
    </template>
    <v-card elevation="0" max-width="1000px" width="100%">
      <v-tabs v-model="tabs" fixed-tabs>
        <v-tab>Ипотечный</v-tab>
        <v-tab>Потребительский</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <calculator :sum-credit="sum" :sum-initial="initial" :type="type"/>
        </v-tab-item>
        <v-tab-item>
          <calculator :sum-credit="sum" type="potreb"/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <template v-slot:actions>
      <v-btn color="primary" @click="close">Закрыть</v-btn>
    </template>
  </modal>
</template>
<script>

import Modal      from "@/tags/Modal.vue";
import Calculator from "@/views/calculator/Calculator.vue";

export default {
  name:       "CalculatorView",
  components: {Calculator, Modal},
  props: ['sum', 'initial', 'type'],
  data() {
    return {
      modal: false,
      tabs: this.type === 'potreb' ? 1 : 0,
    }
  },
  methods: {
    close() {
      this.modal = false;
    }
  }
}
</script>


<style scoped>

</style>