<template>
  <div id="SectionTeam" class="wrap mx-md-n6 pa-0 mt-16">
    <section class="container text--white pa-md-0">
      <v-row>
        <v-col class="pt-md-16 text-center" cols="12" md="11" offset-md="1">
          <div class="span-accent mt-mb-n8 mb-mb-4">Команда</div>
          <div class="d-flex justify-center justify-md-space-between flex-wrap flex-md-nowrap">
            <div v-for="item in team" :key="item.photo" class="item">
              <img :src="item.photo" alt="Team Member" class="team-member"/>
              <h3 class="text-3xl font-bold">{{ item.name }}</h3>
              <p class="text-lg font-medium">{{ item.title }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>

</template>
<script>

export default {
  name: "SectionTeam",
  data() {
    return {
      team: [
        {
          name:  "Ибрагим Бадалов",
          title: "Основатель проекта",
          photo: require("@/assets/index/team/1.png"),
        },
        {
          name:  "Алексей Маринюк",
          title: "Куратор по сделкам",
          photo: require("@/assets/index/team/2.png"),
        },
        {
          name:  "Кристина Шевченко",
          title: "Техническая поддержка pro CRM SF",
          photo: require("@/assets/index/team/3.png"),
        },
      ],

    }
  }
}
</script>

<style scoped>
.wrap {
  background: #232936;
}

.item {
  color: white;
  text-align: center;
  margin: 0 5px;

  .img {

  }
}
</style>